<template>
  <div class="app-layout__navbar">
    <va-navbar>
      <template v-slot:left>
        <div class="left">
          <va-icon-menu-collapsed
            @click="isSidebarMinimized = !isSidebarMinimized"
            :class="{ 'x-flip': isSidebarMinimized }"
            class="va-navbar__item"
            :color="colors.primary"
          />
          <router-link to="/">
            <div class="flex-center">
              <div class="pa-3 logo-border">
                <img :src="require('@/assets/Galileo_Half_Light_675x315.png')" height="45" v-if="darkMode">
                <img :src="require('@/assets/Galileo_Half_Dark_675x315.png')" height="45" v-else>
              </div>
              <div style="padding-top: 0.5rem !important;" class="pa-3 flex-center">
                <h1 class="swarm-h1">SWARM&trade;</h1>
                <h3 class="text-danger" style="margin-left: 1rem; margin-top: 0.5rem;" v-if="isDev">Development Server</h3>
              </div>
            </div>
          </router-link>
        </div>
      </template>
      <template #right v-if="!isMobile">
        <va-switch v-model="darkMode" size="small" true-inner-label="Dark Mode" false-inner-label="Light Mode" />
        <app-navbar-actions
          class="app-navbar__actions md5 lg4"
          :user-name="userName"
        />
      </template>
    </va-navbar>
  </div>
</template>

<script>
import { useColors } from 'vuestic-ui'
import { useStore } from 'vuex'
import { computed } from 'vue'
import VaIconMenuCollapsed from '@/components/icons/VaIconMenuCollapsed'
import AppNavbarActions from './components/AppNavbarActions'
import vuesticGlobalConfig from '@/services/vuestic-ui/global-config'
import { useTheme } from '@/services/vuestic-ui/themes'
import { isMobile } from 'mobile-device-detect'

export default {
  components: { AppNavbarActions, VaIconMenuCollapsed },
  setup() {
    if (localStorage.getItem('darkMode') && localStorage.getItem('darkMode') === 'true') {
      const theme = useTheme()
      theme.setTheme('DARK')
    }

    const { getColors } = useColors()
    const colors = computed(() => getColors() )
    const store = useStore()

    const isSidebarMinimized = computed({
      get: () => store.state.isSidebarMinimized,
      set: (value) => store.commit('updateSidebarCollapsedState', value)
    })

    return {
      colors,
      isSidebarMinimized
    }
  },
  data () {
    return {
      isMobile,
      userName: this.$store.state.userName,
      vuesticGlobalConfig,
      darkMode: localStorage.getItem('darkMode') && localStorage.getItem('darkMode') === 'true' ? true : false,
      isDev: process.env.VUE_APP_SERVER_ENV ? process.env.VUE_APP_SERVER_ENV === 'development' : false
    }
  },
  watch: {
    darkMode (newValue, oldValue) {
      if (newValue !== oldValue) {
        localStorage.setItem('darkMode', newValue)

        const theme = useTheme()
        if (newValue) {
          theme.setTheme('DARK')
        } else {
          theme.setTheme('LIGHT')
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  .va-navbar {
    box-shadow: var(--va-box-shadow);
    z-index: 2;
    &__center {
      @media screen and (max-width: 1200px) {
        .app-navbar__github-button {
          display: none;
        }
      }
      @media screen and (max-width: 950px) {
        .app-navbar__text {
          display: none;
        }
      }
    }

    @media screen and (max-width: 950px) {
      .left {
        width: 100%;
      }
      .app-navbar__actions {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .left {
    display: flex;
    align-items: center;
    & > * {
      margin-right: 1.5rem;
    }
    & > *:last-child {
      margin-right: 0;
    }
  }

  .x-flip {
    transform: scaleX(-100%);
  }

  .app-navbar__text > * {
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }

  .swarm-h1 {
    font-size: 1.2rem;
    color: var(--va-primary);
    letter-spacing: 0.2rem;
  }

  .logo-border {
    border-right: var(--va-light-gray) solid 1px;
  }

  .text-danger {
    color: var(--va-danger);
  }
</style>
