<template>
  <div class="auth-layout row align-content--center" :class="isMobile ? '' : 'auth-layout-extended'">
    <div class="flex xs12 pa-3">
      <div class="d-flex justify--center">
        <va-card class="auth-layout__card">
          <va-card-content>
            <div class="pa-3 xs3 flex-center">
              <div class="pa-3 xs12" style="border-right: #e6e9ec solid 1px;" v-if="isMobile">
                <img :src="require('@/assets/Galileo_Half_Light_675x315.png')" height="50" v-if="darkMode">
                <img :src="require('@/assets/Galileo_Half_Dark_675x315.png')" height="50" v-else>
              </div>
              <div class="pa-3 xs12" style="border-right: #e6e9ec solid 1px;" v-else>
                <img :src="require('@/assets/Galileo_Half_Light_675x315.png')" height="135" v-if="darkMode">
                <img :src="require('@/assets/Galileo_Half_Dark_675x315.png')" height="135" v-else>
              </div>
              <div class="pa-3 xs3 text-center">
                <h1 :class="isMobile ? 'swarm-h1-mobile' : 'swarm-h1'">SWARM&trade;</h1>
                <h3 class="text-danger" v-if="isDev">Development Server</h3>
              </div>
            </div>
            <va-separator />

            <div class="pa-3">
              <router-view />
            </div>
          </va-card-content>
        </va-card>
      </div>
      <div class="flex-center xs12 md-12">
        &copy; 2022-{{ currentYear }} Galileo Group, Inc. All Rights Reserved.
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { isMobile } from 'mobile-device-detect'
export default {
  name: "AuthLayout",
  data() {
    return {
      isMobile,
      selectedTabIndex: 0,
      currentYear: moment().format('YYYY'),
      darkMode: localStorage.getItem('darkMode') && localStorage.getItem('darkMode') === 'true' ? true : false,
      isDev: process.env.VUE_APP_SERVER_ENV ? process.env.VUE_APP_SERVER_ENV === 'development' : false
    };
  },
  computed: {
    tabIndex: {
      set(tabName) {
        this.$router.push({ name: tabName });
      },
      get() {
        return this.$route.name;
      }
    }
  }
};
</script>

<style lang="scss">
.auth-layout {
  background-image: linear-gradient(to right, var(--va-background), var(--va-white));

  &__card {
    width: 100%;
    max-width: 600px;
  }
}

.auth-layout-extended {
  min-height: 100vh;
}

.swarm-h1 {
  font-size: 2.5rem;
  color: var(--va-primary);
  letter-spacing: 0.2rem;
  margin-top: 0.5rem;
}

.swarm-h1-mobile {
  font-size: 1.5rem;
  color: var(--va-primary);
  letter-spacing: 0.2rem;
  margin-top: 0.5rem;
}

.text-danger {
  color: var(--va-danger);
}
</style>
