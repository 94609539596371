require('dotenv').config()
import { VuesticPlugin } from 'vuestic-ui';
import { createApp } from 'vue'
import { createGtm } from 'vue-gtm'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuesticGlobalConfig from './services/vuestic-ui/global-config'
import 'bootstrap'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

const i18nConfig = {
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: require('@/i18n/en.json')
  }
}

const app = createApp(App)
app.use(store)

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (store.state.token) {
      next()
    } else {
      router.push({name: 'login', query: { redirect: to.path }})
    }
  } else {
    next()
  }
})

app.use(router)
// if (process.env.VUE_APP_GTM_ENABLED === 'true') {
//   const gtmConfig = {
//     id: process.env.VUE_APP_GTM_KEY,
//     debug: false,
//     vueRouter: router,
//   }
//   app.use(createGtm(gtmConfig))
// }
app.use(createI18n(i18nConfig))
app.use(VuesticPlugin, vuesticGlobalConfig)
app.use(VueLoading, {
    isFullPage: true,
    color: vuesticGlobalConfig.colors.primary,
    backgroundColor: '#FFFFFF',
    height: 96,
    width: 96,
    loader: 'bars'
})
app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: 'geometry'
  }
})
app.use(VueViewer)
app.mount('#app')
