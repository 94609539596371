<template>
  <va-accordion class="sidebar-accordion va-sidebar__menu__inner" v-model="accordionValue" multiply>
    <va-collapse v-for="(route, idx) in items" :key="idx">
      <template #header>
        <va-sidebar-item :active="isRouteActive(route)" :to="route.children ? undefined : { name: route.name }">
          <va-sidebar-item-content>
            <va-icon :name="route.meta.icon" class="va-sidebar-item__icon"/>

            <va-sidebar-item-title>
              {{ $t(route.displayName) }}
            </va-sidebar-item-title>

            <va-icon v-if="route.children" :name="accordionValue[idx] ? 'expand_less' : 'expand_more'" />
          </va-sidebar-item-content>
        </va-sidebar-item>
      </template>
      <template v-for="(child, index) in route.children" :key="index">
        <va-sidebar-item :active="isRouteActive(child)" :to="{ name: child.name }">
          <va-sidebar-item-content>
            <div class="va-sidebar-item__icon"/>

            <va-sidebar-item-title>
              {{ $t(child.displayName) }}
            </va-sidebar-item-title>
          </va-sidebar-item-content>
        </va-sidebar-item>
      </template>
    </va-collapse>
    <va-collapse v-if="isMobile">
      <template #header>
        <va-sidebar-item :to="{ name: 'profile' }">
          <va-sidebar-item-content>
            <va-icon name="vuestic-iconset-settings" class="va-sidebar-item__icon"/>
            <va-sidebar-item-title>
              My Profile
            </va-sidebar-item-title>
          </va-sidebar-item-content>
        </va-sidebar-item>
      </template>
    </va-collapse>
    <va-collapse v-if="isMobile">
      <template #header>
        <va-sidebar-item :to="{ name: 'login' }">
          <va-sidebar-item-content>
            <va-icon name="ion-md-power" class="va-sidebar-item__icon"/>
            <va-sidebar-item-title>
              Logout
            </va-sidebar-item-title>
          </va-sidebar-item-content>
        </va-sidebar-item>
      </template>
    </va-collapse>
    <va-collapse v-if="isMobile">
      <template #header>
        <va-sidebar-item>
          <va-sidebar-item-content>
            <va-sidebar-item-title>
              <va-switch v-model="darkMode" size="small" true-inner-label="Dark Mode" false-inner-label="Light Mode" />
            </va-sidebar-item-title>
          </va-sidebar-item-content>
        </va-sidebar-item>
      </template>
    </va-collapse>
  </va-accordion>
</template>

<script>
import { useTheme } from '@/services/vuestic-ui/themes'
import { isMobile } from 'mobile-device-detect'
export default {
  name: "AppMenuAccordion",
  props: {
    items: { type: Array, default: () => [] }
  },
  data () {
    return {
      accordionValue: [],
      isMobile,
      darkMode: localStorage.getItem('darkMode') && localStorage.getItem('darkMode') === 'true' ? true : false
    }
  },
  setup () {
    if (localStorage.getItem('darkMode') && localStorage.getItem('darkMode') === 'true') {
      const theme = useTheme()
      theme.setTheme('DARK')
    }
  },
  mounted () {
    this.accordionValue = this.items.map(i => this.isItemExpanded(i));
  },
  watch: {
    darkMode (newValue, oldValue) {
      if (newValue !== oldValue) {
        localStorage.setItem('darkMode', newValue)

        const theme = useTheme()
        if (newValue) {
          theme.setTheme('DARK')
        } else {
          theme.setTheme('LIGHT')
        }
      }
    }
  },
  methods: {
    isGroup(item) {
      return !!item.children;
    },
    isRouteActive(item) {
      return item.name === this.$route.name;
    },
    isItemExpanded(item) {
      if (!item.children) {
        return false;
      }

      const isCurrentItemActive = this.isRouteActive(item);
      const isChildActive = !!item.children.find(child =>
        child.children ? this.isItemExpanded(child) : this.isRouteActive(child)
      );

      return isCurrentItemActive || isChildActive;
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
