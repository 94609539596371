import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  strict: true, // process.env.NODE_ENV !== 'production',
  plugins: [createPersistedState()],
  state: {
    isSidebarMinimized: false,
    userName: 'User',
    token: null,
    uid: null,
    access: null,
    selectedItems: []
  },
  mutations: {
    updateSidebarCollapsedState(state, isSidebarMinimized) {
      state.isSidebarMinimized = isSidebarMinimized
    },
    changeUserName(state, newUserName) {
      state.userName = newUserName
    },
    setToken(state, token) {
      state.token = token
    },
    removeToken(state) {
      state.token = null
    },
    setUID(state, uid) {
      state.uid = uid
    },
    removeUID(state) {
      state.uid = null
    },
    setAccess(state, access) {
      state.access = access
    },
    removeAccess(state) {
      state.access = null
    },
    setSelectedItems(state, items) {
      state.selectedItems = items
    }
  },
})
